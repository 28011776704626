import React, { useEffect } from "react";
import Inputs from "./Inputs";

const Questions = ({
  type,
  control,
  index,
  item,
  register,
  errors,
  watch,
}: {
  type: string;
  control: any;
  index: number;
  item: any;
  register: any;
  errors: any;
  watch: any;
}) => {
  return (
    <>
      <div className="test__name">
        Вопрос {index + 1}: {item.name}
        {item.content ? (
          <img src={`${process.env.REACT_APP_BACK_URL}contents/get_content?content_id=${item.content.id}`} />
        ) : (
          ""
        )}
      </div>
      {item.description ? <div className="test__discr" style={{marginBottom: '15px'}}>{item.description}</div> : ""}

      <Inputs register={register} type={type} control={control} index={index} errors={errors} />
    </>
  );
};

export default Questions;
