import React, { lazy, Suspense } from "react";
import { createBrowserRouter, Link, Navigate } from "react-router-dom";
import App from "./components/App/App";
import Error from "./components/Error/Error";
import {
  CERTIFICATION_PATH,
  DOCUMENTS_PATH,
  ENTRY_PATH,
  ERROR_PATH,
  LESSON_PATH,
  LESSONS_PATH,
  MAIN_PATH,
  NOT_COUNT_PATH,
  PAYMENT_FAILED_PATH,
  PAYMENT_SUCCESSFUL_PATH,
  PREPARATION_PATH,
  REGISTRATION_PATH,
  REGISTRATION_PATH_1,
  REGISTRATION_PATH_2,
  REGISTRATION_PATH_3,
  REGISTRATION_PATH_4,
  REGISTRATION_PATH_GENERAL,
  TEXTBOOKS_PATH,
} from "./utils/const";

import Profile from "./components/Profile/Profile";
import RegistrationWrap from "./components/Registration/RegistrationWrap";
import Loading from "./components/Loading/Loading";
import ProfileTextbooks from "./components/TextBook/ProfileTextbooks";
import ProfileCertification from "./components/Profile/ProfilePage/ProfileCertification";
import ProfileCertificationTest from "./components/Profile/ProfilePage/ProfileCertificationTest";
import ProfilePreparation from "./components/Profile/ProfilePage/ProfilePreparation";
import LessonsList from "./components/Lessons/LessonsList";
import Lesson from "./components/Lessons/Lesson";
import TextBook from "./components/TextBook/TextBook";
import NotCount from "./pages/NotCount";
import { RegistrationFour } from "./components/Registration/RegistrationPages/RegistrationFour";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import PaymentFailed from "./pages/PaymentFailed";

const ProfileMain = lazy(
  () => import("./components/Profile/ProfilePage/ProfileMain")
);
const ProfileDocuments = lazy(
  () => import("./components/Profile/ProfilePage/ProfileDoc/ProfileDocuments")
);
const RegistrationOne = lazy(
  () => import("./components/Registration/RegistrationPages/RegistrationOne")
);
const RegistrationTwo = lazy(
  () => import("./components/Registration/RegistrationPages/RegistrationTwo")
);
const RegistrationThree = lazy(
  () => import("./components/Registration/RegistrationPages/RegistrationThree")
);
const Login = lazy(() => import("./components/Login/Login"));
const Registration = lazy(
  () => import("./components/Registration/Registration")
);

const router = createBrowserRouter([
  {
    path: MAIN_PATH,
    element: <App />,
    errorElement: <Navigate to={ERROR_PATH} replace />,
    handle: { crumb: () => <Link to={MAIN_PATH}>Главная</Link> },
    children: [
      {
        path: MAIN_PATH,
        element: <Profile />,
        handle: { crumb: () => <Link to={MAIN_PATH}>Личный кабинет</Link> },
        children: [
          {
            path: MAIN_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileMain />
              </Suspense>
            ),
          },
          {
            path: DOCUMENTS_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileDocuments />
              </Suspense>
            ),
          },
          {
            path: TEXTBOOKS_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileTextbooks />
              </Suspense>
            ),
          },
          {
            path: `${TEXTBOOKS_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <TextBook />
              </Suspense>
            ),
          },
          {
            path: CERTIFICATION_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileCertification />
              </Suspense>
            ),
          },
          {
            path: `${CERTIFICATION_PATH}/:discipline_id`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileCertificationTest />
              </Suspense>
            ),
          },
          {
            path: PREPARATION_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfilePreparation />
              </Suspense>
            ),
          },
          {
            path: `${LESSONS_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <LessonsList />
              </Suspense>
            ),
          },
          {
            path: `${LESSON_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <Lesson />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ENTRY_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        ),
        handle: { crumb: () => <Link to={ENTRY_PATH}>Вход</Link> },
      },
      {
        path: REGISTRATION_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <Registration />
          </Suspense>
        ),
        handle: {
          crumb: () => <Link to={REGISTRATION_PATH}>Регистрация</Link>,
        },
      },
      {
        path: REGISTRATION_PATH_GENERAL,
        element: <RegistrationWrap />,
        children: [
          {
            path: REGISTRATION_PATH_1,

            element: (
              <Suspense fallback={<Loading />}>
                <RegistrationOne />
              </Suspense>
            ),
            handle: {
              crumb: () => <Link to={REGISTRATION_PATH_1}>Регистрация</Link>,
            },
          },
          {
            path: REGISTRATION_PATH_2,
            element: (
              <Suspense fallback={<Loading />}>
                <RegistrationTwo />
              </Suspense>
            ),
            handle: {
              crumb: () => <Link to={REGISTRATION_PATH_2}>Регистрация</Link>,
            },
          },
          {
            path: REGISTRATION_PATH_3,
            element: (
              <Suspense fallback={<Loading />}>
                <RegistrationThree />
              </Suspense>
            ),
            handle: {
              crumb: () => <Link to={REGISTRATION_PATH_3}>Регистрация</Link>,
            },
          },
          {
            path: REGISTRATION_PATH_4,
            element: (
              <Suspense fallback={<Loading />}>
                <RegistrationFour />
              </Suspense>
            ),
            handle: {
              crumb: () => <Link to={REGISTRATION_PATH_4}>Регистрация</Link>,
            },
          },
        ],
      },
      {
        path: ERROR_PATH,
        element: <Error />,
        handle: { crumb: () => <Link to={ERROR_PATH}>Ошибка</Link> },
      },
      {
        path: NOT_COUNT_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <NotCount />
          </Suspense>
        ),
        handle: { crumb: () => <Link to={ENTRY_PATH}>Вход</Link> },
      },
      {
        path: PAYMENT_SUCCESSFUL_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <PaymentSuccessful />
          </Suspense>
        ),
      },
      {
        path: PAYMENT_FAILED_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <PaymentFailed />
          </Suspense>
        ),
      },
    ],
  },
]);

export default router;
