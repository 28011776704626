import {
  RegistrationStepOneFormType,
  RegistrationStepTwoFormType,
  uploadBirthCertificateInformationType,
  uploadDocType,
  uploadParentInformationType,
  uploadParentPassportInformationType,
  uploadPersonalInformationType,
} from "../type/type";
import { documentAPI, registrationAPI } from "../api/api";
import { loadingStatus, setErrorRedux } from "./appReducer";
import { setAuthData, setUserId } from "./authReducer";
import {
  setAgreements,
  setClass,
  setCost,
  setDisciplines,
  setRegistrationOne,
  setRegistrationOneData,
  setRegistrationTwo,
  setRegistrationTwoData,
  setSchools,
  setYearPrice,
} from "./slices/registrationSlice";

export const getClasses = (numberClass: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await registrationAPI.getCost(numberClass, false);
    dispatch(setClass(numberClass));
    dispatch(setCost(response.data));
    localStorage.setItem("currentRegistrationClass", String(numberClass));
  } catch (err: any) {
    if (!err.response) {
      console.error("Нет ответа от сервера");
    } else console.log(err);
  }

  dispatch(loadingStatus(false));
};

export const getSchools = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await registrationAPI.getSchools();
    dispatch(setSchools(response.data));
  } catch (err: any) {
    if (!err.response) {
      console.error("Нет ответа от сервера");
    } else console.log(err);
  }

  dispatch(loadingStatus(false));
};

export const getDisciplines = (classId: number) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await registrationAPI.getDisciplines(classId);
    dispatch(setDisciplines(response.data));
  } catch (err: any) {
    if (!err.response) {
      console.error("Нет ответа от сервера");
    } else console.log(err);
  }

  dispatch(loadingStatus(false));
};

export const registration =
  (data: RegistrationStepOneFormType) => async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await registrationAPI.registration(data);
      dispatch(setUserId(response.data.user_id));
      dispatch(setYearPrice(response.data.year_price));
      dispatch(setRegistrationOne(true));
      dispatch(setRegistrationOneData(data));

      localStorage.setItem("registrationOneData", JSON.stringify(data));
      localStorage.setItem("price", JSON.stringify(response.data.year_price));
      localStorage.setItem(
        "price_without_additions",
        JSON.stringify(response.data.price_without_additions)
      );
    } catch (err: any) {
      if (!err.response) {
        console.error(err);
      } else if (err.response.data.detail) {
        switch (err.response.data.detail) {
          case "This phone number is already exists!": {
            console.warn("Пользователь с таким телефоном уже зарегистрирован");

            dispatch(
              setErrorRedux({
                type: "phone_number",
                message: "Пользователь с таким телефоном уже зарегистрирован",
                shortMessage: "Телефон занят",
              })
            );
            break;
          }
          case "This username is already exists!": {
            console.warn("Пользователь с таким email уже зарегистрирован");

            dispatch(
              setErrorRedux({
                type: "email",
                message: "Пользователь с таким email уже зарегистрирован",
                shortMessage: "email занят",
              })
            );
            break;
          }
        }
      } else console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const registrationOverwrite =
  (data: RegistrationStepOneFormType) => async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await registrationAPI.registrationOverwrite(data);
      dispatch(setUserId(response.data.user_id));
      dispatch(setYearPrice(response.data.year_price));
      dispatch(setRegistrationOne(true));
      dispatch(setRegistrationOneData(data));
      localStorage.setItem("registrationOneData", JSON.stringify(data));
      localStorage.setItem("price", JSON.stringify(response.data.year_price));
      localStorage.setItem(
        "price_without_additions",
        JSON.stringify(response.data.price_without_additions)
      );
    } catch (err: any) {
      if (!err.response) {
        console.error("Нет ответа от сервера");
      } else if (err.response.data.detail) {
        switch (err.response.data.detail) {
          case "This phone number is already exists!": {
            console.warn("Пользователь с таким телефоном уже зарегистрирован");

            dispatch(
              setErrorRedux({
                type: "phone_number",
                message: "Пользователь с таким телефоном уже зарегистрирован",
                shortMessage: "Телефон занят",
              })
            );
            break;
          }
          case "This username is already exists!": {
            console.warn("Пользователь с таким email уже зарегистрирован");

            dispatch(
              setErrorRedux({
                type: "email",
                message: "Пользователь с таким email уже зарегистрирован",
                shortMessage: "email занят",
              })
            );
            break;
          }
        }
      } else console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const registrationTwo =
  (
    dataPersonal: uploadPersonalInformationType,
    dataParent: uploadParentInformationType,
    dataBirthCertificate: uploadBirthCertificateInformationType,
    dataParentPassport: uploadParentPassportInformationType,
    dataUploadPassportDoc1: uploadDocType,
    dataUploadPassportDoc2: uploadDocType,
    dataUploadPassportStudentDoc1: uploadDocType | undefined,
    dataUploadPassportStudentDoc2: uploadDocType | undefined,
    dataDocBirthCertificate: uploadDocType,
    data: RegistrationStepTwoFormType
  ) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response1 = await registrationAPI.uploadPersonalInformation(
        dataPersonal
      );

      const response2 = await registrationAPI.uploadParentInformation(
        dataParent
      );

      const response3 = await registrationAPI.uploadBirthCertificateInformation(
        dataBirthCertificate
      );

      const response4 = await registrationAPI.uploadParentPassportInformation(
        dataParentPassport
      );

      const response5 = await registrationAPI.uploadDocuments(
        dataUploadPassportDoc1
      );

      const response7 = await registrationAPI.uploadDocuments(
        dataUploadPassportDoc2
      );

      if (dataUploadPassportStudentDoc1)
        await registrationAPI.uploadDocuments(dataUploadPassportStudentDoc1);

      if (dataUploadPassportStudentDoc2)
        await registrationAPI.uploadDocuments(dataUploadPassportStudentDoc2);

      const response6 = await registrationAPI.uploadDocuments(
        dataDocBirthCertificate
      );

      dispatch(setRegistrationTwo(true));
      dispatch(setRegistrationTwoData(data));
      const {
        passport_image_first,
        passport_image_two,
        BirthCertificateImage,
        ...dataForLocal
      } = data;
      localStorage.setItem("registrationTwoData", JSON.stringify(dataForLocal));
    } catch (err: any) {
      console.log(err);
    }

    dispatch(loadingStatus(false));
  };

export const getAgreements = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await documentAPI.getAgreement();
    dispatch(
      setAgreements({
        number: response.data.number,
        date: response.data.date,
      })
    );
  } catch (err: any) {
    console.log(err);
  }

  dispatch(loadingStatus(false));
};
