import React from 'react';
import {useMatches} from "react-router-dom";

const Breadcrumbs:React.FC = () => {

    let matches = useMatches();

    //@ts-ignore
    let crumbs = matches.filter((match) => Boolean(match.handle?.crumb))
        //@ts-ignore
        .map((match) => match.handle.crumb(match.data));

    return (
        <div className="breadcrumbs">
            <div className="container">

                <ul className="breadcrumbs__list">
                    {crumbs.map((crumb, index) => (
                        <li className="breadcrumbs__item" key={index}>{crumb}</li>
                    ))}
                </ul>

            </div>
        </div>
    );
};

export default Breadcrumbs;