import { Font, StyleSheet } from "@react-pdf/renderer";
// @ts-ignore
import fontsTimesNewRoman from "../../fonts/TimesNewRomanPSMT.ttf";
// @ts-ignore
import fontsTimesNewRomanBold from "../../fonts/TimesNewRomanPS-BoldMT.ttf";
// @ts-ignore
import fontsTimesNewRomanBoldItalic from "../../fonts/TimesNewRomanPS-BoldItalicMT.ttf";

Font.register({
  family: "Times New Roman",
  fonts: [
    { src: fontsTimesNewRoman, fontWeight: 400 },
    { src: fontsTimesNewRomanBold, fontWeight: 700 },
    { src: fontsTimesNewRomanBoldItalic, fontWeight: 700, fontStyle: "italic" },
  ],
});

export const styles = StyleSheet.create({
  document: {
    fontFamily: "Times New Roman",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  page: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    marginBottom: "30px",
    paddingTop: "1.83cm",
    paddingBottom: "1.5cm",
    paddingLeft: "2.82cm",
    paddingRight: "1.09cm",
  },
  section: {},
  textTitle: {
    textAlign: "center",
    fontWeight: 700,
  },
  JCSB: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  MB: {
    marginBottom: "12pt",
  },
  MBS: {
    marginBottom: "2pt",
  },
  MBB: {
    marginBottom: "24pt",
  },
  TAJ: {
    textAlign: "justify",
  },
  FW700: {
    fontWeight: 700,
  },
  list: {},
  Item: {
    marginBottom: "4pt",
  },
  italic: {
    fontStyle: "italic",
  },
  section50: {
    flex: 1,
  },
  MR: {
    marginRight: "30pt",
  },
  TDU: {
    textDecoration: "underline",
  },
  podpisBlock: {
    position: "relative",
    display: "flex",
  },
  pechat: {
    position: "absolute",
    width: "140px",
    height: "140px",
    objectFit: "contain",
    left: "0",
    zIndex: "1",
    pointerEvents: "none",
    top: "-10px",
    float: 'left'
  },
  podpisBlockWrap: {
    marginBottom: '100px'
  },
  podpis: {
    position: "relative",
    width: "200px",
    paddingLeft: '90px',
    zIndex: "1",
    borderBottom: "1px solid #67676A",
  },
  podpisImg: {
    position: 'absolute',
    zIndex: '2',
    width: '100px',
    height: 'auto',
    objectFit: 'contain',
    left: '0',
    top: '-40px'
  }
});

export const stylesViewer = StyleSheet.create({
  main: {
    width: "100%",
    border: "none",
    display: "flex",
    height: window.innerHeight,
  },
});
