import React, { useEffect, useState } from "react";
import TextBookItem from "./TextBookItem";
import { TextBookService } from "../../services/TextBookService";

const ProfileTextbooks = () => {
  const [listBooks, setListBooks] = useState<Array<any>>([]);
  const [count, setCount] = useState<null | number>(null);
  const [limit, setLimit] = useState<number>(12);

  const initData = async () => {
    try {
      const response = await TextBookService.getMyBooks({ limit });
      setCount(response.data.amount_of_books);
      setListBooks(response.data.books);
    } catch (e) {
      console.error(e);
    }
  };

  const onAddLimit = () => {
    setLimit(limit + 12);
  };

  useEffect(() => {
    initData();
  }, [limit]);

  return (
    <div>
      <div className="subjects__title textBook-students__title _h3">Учебники</div>

      <ul className="textBook-students__list">
        {listBooks.map((e: any) => (
          <TextBookItem key={e.id} name={e.name} id={e.id} author={e.author} url={e.thumbnail.url} />
        ))}
      </ul>
      {count && limit < count ? (
        <button className="textBook-students__btn btn btn_4" onClick={onAddLimit}>
          Открыть еще
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProfileTextbooks;
