import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadingStatus } from "./appReducer";
import { authAPI } from "../api/api";
import { LoginMailPopUpType } from "../type/type";

export interface InitialStateType {
  isAuth: boolean;
  login: string | null;
  userId: number | null;
  error: string | null;
  role: string | null;
  className: number | null;
  firstName: string | null;
  secondName: string | null;
  school: string | null;
}

const initialState: InitialStateType = {
  isAuth: false,
  login: null,
  userId: null,
  error: null,
  role: null,
  className: null,
  firstName: null,
  secondName: null,
  school: null,
};

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setAuthData: (
      state,
      action: PayloadAction<{ className: number; firstName: string; secondName: string; school: string; role: string }>
    ) => {
      state.className = action.payload.className;
      state.firstName = action.payload.firstName;
      state.secondName = action.payload.secondName;
      state.secondName = action.payload.secondName;
      state.school = action.payload.school;
      state.role = action.payload.role;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    removeError: (state) => {
      state.error = null;
    },
  },
});

export const { setUserId, setAuth, setAuthData, setError, removeError } = authReducer.actions;

export const login = (data: LoginMailPopUpType) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await authAPI.login(data);
    let dataForStore = {
      className: response.data.class.name,
      firstName: response.data.student_information?.first_name,
      secondName: response.data.student_information?.second_name,
      role: response.data.type,
      school: response.data.school.name,
    };

    dispatch(setAuthData(dataForStore));
    dispatch(setAuth(true));
    dispatch(removeError());
  } catch (err: any) {
    if (err.response) {
      if (err.response.data.detail) {
        dispatch(setError(err.response.data.detail));
      }
      console.error(err.response);
    }
    console.error(err);
  }

  dispatch(loadingStatus(false));
};

export const logout = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await authAPI.logout();
    dispatch(setAuth(false));
  } catch (err: any) {
    if (err.response) {
      console.error(err.response);
    }
    console.error(err);
  }

  dispatch(loadingStatus(false));
};

export default authReducer.reducer;
