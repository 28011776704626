import React from "react";
import { useAppDispatch } from "../store/hooks";
import { logout } from "../store/authReducer";
import { useNavigate } from "react-router-dom";
import { MAIN_PATH } from "../utils/const";

const NotCount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onLogout = () => {
    dispatch(logout());
    navigate(MAIN_PATH);
  };
  return (
    <div className="notification__wrapper">
      <div className="notification__block">
        <div className="notification__icon"></div>
        <div className="notification__content">
          <div className="notification__title _h4">Не оплачено обучение</div>
          <div>
            Пожалуйста, свяжитесь с <br /> нашим администратором
          </div>
          <div className="btn notification__btn">
            <button onClick={onLogout}>Выйти из аккаунта</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotCount;
