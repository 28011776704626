import {
  RegistrationPopUpType,
  RegistrationStepOneFormType,
  RegistrationStepTwoFormType,
} from "../../type/type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OPS } from "pdfjs-dist";
import setTextMatrix = OPS.setTextMatrix;

export type itemSubject = {
  name: string;
  id: number;
  icon: string;
};

export interface InitialStateType {
  startRegistration: boolean;
  name: string | null;
  surname: string | null;
  classNumber: number | null;
  cost: number | null;
  schools: Array<{ id: number; name: string } | null>;
  disciplines: Array<itemSubject | null>;
  registrationOne: boolean | null;
  registrationTwo: boolean | null;
  registrationThree: boolean | null;
  registrationFour: boolean | null;
  dataOne: RegistrationStepOneFormType | null;
  error: { type: string; message: string; shortMessage: string } | null;
  yearPrice: number | null;
  dataTwo: RegistrationStepTwoFormType | null;
  date: string | null;
  numberAgreement: string | null;
}

const initialState: InitialStateType = {
  startRegistration: false,
  name: null,
  surname: null,
  classNumber: null,
  cost: null,
  schools: [null],
  disciplines: [null],
  registrationOne: null,
  dataOne: null,
  error: null,
  registrationTwo: null,
  yearPrice: null,
  dataTwo: null,
  registrationThree: null,
  registrationFour: null,
  date: null,
  numberAgreement: null,
};

export const registrationReducer = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setStartData: (state, action: PayloadAction<RegistrationPopUpType>) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.classNumber = action.payload.classNumber;
      state.registrationOne = null;
      state.registrationTwo = null;
      state.yearPrice = null;
      localStorage.setItem("currentRegistrationName", action.payload.name);
      localStorage.setItem(
        "currentRegistrationSurname",
        action.payload.surname
      );
      localStorage.setItem(
        "currentRegistrationClass",
        String(action.payload.classNumber)
      );
    },
    setStatusRegistrationStart: (state, action: PayloadAction<boolean>) => {
      state.startRegistration = action.payload;
      localStorage.removeItem("registrationOneData");
      localStorage.removeItem("registrationTwoData");
      localStorage.removeItem("registrationOne");
      localStorage.removeItem("registrationTwo");
      localStorage.removeItem("registrationThree");
      localStorage.removeItem("price");
      localStorage.removeItem("date");
      localStorage.removeItem("numberAgreement");
      localStorage.removeItem("price_without_additions");
    },
    setClass: (state, action: PayloadAction<number>) => {
      state.classNumber = action.payload;
    },
    setCost: (state, action: PayloadAction<number>) => {
      state.cost = action.payload;
    },
    setSchools: (
      state,
      action: PayloadAction<Array<{ id: number; name: string }>>
    ) => {
      state.schools = action.payload;
    },
    setYearPrice: (state, action: PayloadAction<number>) => {
      state.yearPrice = action.payload;
    },
    setDisciplines: (state, action: PayloadAction<Array<itemSubject>>) => {
      state.disciplines = action.payload;
    },
    setRegistrationOne: (state, action: PayloadAction<boolean>) => {
      state.registrationOne = action.payload;
      localStorage.setItem("registrationOne", String(action.payload));
    },
    setRegistrationTwo: (state, action: PayloadAction<boolean>) => {
      state.registrationTwo = action.payload;
      localStorage.setItem("registrationTwo", String(action.payload));
    },
    setRegistrationThree: (state, action: PayloadAction<boolean>) => {
      state.registrationThree = action.payload;
      localStorage.setItem("registrationThree", String(action.payload));
    },
    setRegistrationFour: (state, action: PayloadAction<boolean>) => {
      state.registrationFour = action.payload;
      localStorage.setItem("registrationFour", String(action.payload));
    },
    setRegistrationOneData: (
      state,
      action: PayloadAction<RegistrationStepOneFormType>
    ) => {
      state.dataOne = action.payload;
    },
    setRegistrationTwoData: (
      state,
      action: PayloadAction<RegistrationStepTwoFormType>
    ) => {
      state.dataTwo = action.payload;
    },
    setSuccessQuery: (
      state,
      action: PayloadAction<{ type_error: string; error: string }>
    ) => {
      const nameState = action.payload.type_error;
      // @ts-ignore
      state[`${nameState}`] = action.payload.error;
    },
    setAgreements: (
      state,
      action: PayloadAction<{ date: string; number: string }>
    ) => {
      state.date = action.payload.date;
      state.numberAgreement = action.payload.number;
      localStorage.setItem("date", String(action.payload.date));
      localStorage.setItem("numberAgreement", String(action.payload.number));
    },
  },
});

export const {
  setStartData,
  setStatusRegistrationStart,
  setClass,
  setCost,
  setSchools,
  setDisciplines,
  setRegistrationOne,
  setRegistrationOneData,
  setSuccessQuery,
  setRegistrationTwo,
  setYearPrice,
  setRegistrationTwoData,
  setRegistrationThree,
  setRegistrationFour,
  setAgreements,
} = registrationReducer.actions;

export default registrationReducer.reducer;
