import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import IconStore from "../../media/iconStore";
import RegistrationAside from "./RegistrationAside";
import RegistrationNav from "./RegistrationNav";
import { MAIN_PATH } from "../../utils/const";
import { useAppSelector } from "../../store/hooks";


const RegistrationWrap: React.FC = () => {
    
    const isAuth = useAppSelector((state) => state.auth.isAuth)
    const registerThree = useAppSelector((state) => state.registration.registrationThree)
    
    if (isAuth && registerThree) {
        return <Navigate to={MAIN_PATH}/>
    }
    
    return (
        <section className="cabinet registration">
            
            <img className="registration__decor _1" src={IconStore.DecorReg1} alt="reg1.svg" width="464" height="650"/>
            <img className="registration__decor _2" src={IconStore.DecorReg2} alt="reg2.svg" width="470"
                 height="739"/>
            <img className="registration__decor _3" src={IconStore.DecorReg3} alt="reg3.svg" width="585"
                 height="750"/>
            
            <div className="container">
                <div className="cabinet__grid">
                    
                    <RegistrationAside/>
                    
                    <div className="cabinet__content">
                        
                        <div className="registration__content">
                            
                            <div className="registration__title _h2">
                                Регистрация
                            </div>
                            
                            <RegistrationNav/>
                            
                            <Outlet/>
                        
                        </div>
                    </div>
                </div>
            </div>
        
        </section>
    );
};

export default RegistrationWrap;