import { useEffect, useState } from "react";
import { instance } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { CERTIFICATION_PATH } from "../../../utils/const";
import { Modal } from "antd";

export type CertificationResult = {
  discipline: {
    id: number;
    name: string;
    icon_url: string;
  };
  count_of_attestations: number;
  best_attestation_result: {
    correct_answers_amount: number;
    percent: number;
    user_answers_data: string;
  } | null;
};

export const percentToRate = (percent: number | undefined) => {
  if (!percent) return 2;
  if (percent >= 90) return 5;
  if (percent >= 70) return 4;
  if (percent >= 50) return 3;
  return 2;
};
const percentToTextRate = (
  percent: number | undefined,
  count_of_attestations: number
) => {
  if (!percent) return "Не сдавался";
  const rate = percentToRate(percent);
  if (rate === 2 && count_of_attestations === 3) return "Незачёт";
  return rate + " " + (rate === 5 ? "баллов" : "балла");
};

const ProfileCertification = () => {
  const [certificationResults, setResults] = useState<CertificationResult[]>();

  const navigate = useNavigate();

  useEffect(() => {
    instance
      .get(`tests/attestation/get_results`)
      .then((r) => setResults(r.data));
  }, []);

  const [selectedTest, setSelectedTest] = useState<CertificationResult>();

  return (
    <>
      <div className="certification__title _h3">Аттестация</div>
      <div className="certification__info-block">
        <div className="certification__info-block__background"></div>
        Для сдачи аттестации за учебный год вам доступны тесты по следующим
        предметам.
        <div className="certification__info-block__attention certification__text-bold">
          Внимание! Вам доступны 3 попытки. Время прохождения теста: не
          ограничено
        </div>
      </div>
      <div className="certification__table-wrapper">
        <div className="certification__table">
          <div className="certification__table-line">
            {[
              "Предмет",
              "Лучший результат",
              "Колиество попыток",
              "Перейти к выполнению",
            ].map((cell, i) => (
              <div
                className={
                  "certification__table-cell" +
                  (i === 0 ? " certification__table-cell-subject" : "")
                }
                key={cell}
              >
                {cell}
              </div>
            ))}
          </div>
          <div className="certification__divider"></div>
          {certificationResults?.map((result) => (
            <div className="certification__table-line">
              <div className="certification__table-cell certification__text-bold certification__table-cell-subject">
                {result.discipline.name}
              </div>
              <div
                className={
                  "certification__table-cell" +
                  (percentToRate(result.best_attestation_result?.percent) ===
                    2 && result.count_of_attestations === 3
                    ? " certification__table-cell-red"
                    : "")
                }
              >
                {percentToTextRate(
                  result.best_attestation_result?.percent,
                  result.count_of_attestations
                )}
              </div>
              <div className="certification__table-cell ">
                {result.count_of_attestations === 3 ? (
                  "Попыток нет"
                ) : (
                  <>
                    <span className="certification__available-text">
                      Доступно:
                    </span>
                    {` ${3 - result.count_of_attestations} из 3`}
                  </>
                )}
              </div>
              <div className="certification__table-cell">
                <div
                  className={
                    result.count_of_attestations === 3
                      ? "certification__complete-button"
                      : "certification__start-button"
                  }
                  onClick={() =>
                    result.count_of_attestations !== 3 &&
                    setSelectedTest(result)
                  }
                >
                  {result.count_of_attestations === 3 ? "Выполнено" : "Начать"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        className="certification__attention-modal"
        open={!!selectedTest}
        onCancel={() => setSelectedTest(undefined)}
        title="Внимание!"
        footer={
          <>
            <div className="certification__modal-footer">
              <div
                className="btn certification__modal-footer__white-btn"
                onClick={() =>
                  navigate(
                    CERTIFICATION_PATH +
                      "/" +
                      selectedTest?.discipline.id +
                      "?test_subject=" +
                      selectedTest?.discipline.name
                  )
                }
              >
                Да!
              </div>
              <div
                className="btn certification__modal-footer__danger-btn"
                onClick={() => setSelectedTest(undefined)}
              >
                Нет
              </div>
            </div>
          </>
        }
      >
        Вы переходите к сдаче аттестации. У вас будет 3 попытки. Уверены, что
        хотите начать?
      </Modal>
    </>
  );
};

export default ProfileCertification;
