import React from 'react';
import SubjectPreparationList from "../../SubjectPreparationList/SubjectPreparationList";


const ProfilePreparation = () => {
    
    return (
        <div className="preparation">
            <div className="preparation__title _h2">
                Подготовка
            </div>
    
            <SubjectPreparationList/>
           
        </div>
    );
};

export default ProfilePreparation;