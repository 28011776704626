import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PREPARATION_PATH, LESSONS_PATH } from "../../utils/const";
import { testsApi } from "../../api/api";

const TestSuccess = ({
  idDisciplines,
  testId,
  flag,
}: {
  idDisciplines: number | null;
  testId: number | null;
  flag: boolean;
}) => {
  const route = idDisciplines ? `${LESSONS_PATH}/${idDisciplines}` : PREPARATION_PATH;

  const [countCorrect, setCountCorrect] = useState<null | number>(null);
  const [countAll, setCountAll] = useState<null | number>(null);
  console.log(countCorrect, countAll);

  const getResult = async (id: number) => {
    const { data } = await testsApi.showLastResultTest(id);
    setCountCorrect(data.amount_of_correct_answers);
    setCountAll(data.amount_of_questions);
    console.log(data);
  };

  useEffect(() => {
    if (testId) {
      getResult(testId);
    }
  }, [flag]);

  return (
    <div className="test__success">
      <div className="test__success-title _h3">Ура! Задание выполнено верно</div>
      {countCorrect !== null ? (
        <div style={{ margin: "20px 0" }}>
          Последнее прохождение. Верных ответов {countCorrect} из {countAll}.
        </div>
      ) : (
        ""
      )}

      <div className="test__success-btn-wrap">
        <Link className="test__success-btn-link btn" to={route}>
          Вернуться на главную
        </Link>
        <a className="test__success-btn-link btn btn_2" href="#">
          Следующее задание
        </a>
      </div>
    </div>
  );
};

export default TestSuccess;
