import {configureStore} from "@reduxjs/toolkit";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import registrationReducer from "./slices/registrationSlice";
import profileReducer from "./profileReducer";
import studyBookReducer from "./studyBookReducer";


export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        registration: registrationReducer,
        profile: profileReducer,
        studyBook: studyBookReducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),


    devTools: process.env.NODE_ENV !== 'production',
})


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

//@ts-ignore
window.store = store;