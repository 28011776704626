import { useNavigate } from "react-router-dom";
import { MAIN_PATH } from "../utils/const";

const PaymentSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div className="notification__wrapper">
      <div className="notification__block2">
        <div className="notification__icon3"></div>
        <div className="notification__content">
          <div className="notification__title _h4">Оплата прошла успешно!</div>
          <div className="btn notification__btn">
            <button onClick={() => navigate(MAIN_PATH)}>
              Перейти в личный кабинет
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
