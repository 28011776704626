import { instance } from "../api/axios";
import { openErrorNotification, openSuccessNotification } from "../utils/Notification";

export const TextBookService = {
  async getMyBooks({ offset = 0, limit = 20 }: { offset?: number; limit: number }) {
    const response: any = await instance.get(`books/get_my_books?offset=${offset}&limit=${limit}`).catch((e) => {
      console.error(e);
      openErrorNotification(e?.message, "Ошибка при получения списка учебников");
      return;
    });
    return response;
  },

  async getBookInfo(book_id: number) {
    const response: any = await instance.get(`books/get_book_info?book_id=${book_id}`).catch((e) => {
      console.error(e);
      openErrorNotification(e?.message, "Ошибка при получения учебника");
      return;
    });
    return response;
  },
};
