import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { typeAppDesktop } from '../utils/const'
import { authAPI } from "../api/api";
import { setAuth, setAuthData, setUserId } from './authReducer';


export interface InitialStateType {
    loading: boolean
    showMobMenu: boolean
    showSubMobMenu: boolean
    typeApp: string
    fixedApp: boolean
    error: null | { type: string, message: string, shortMessage: string }
    init: boolean
}

const initialState: InitialStateType = {
    loading: false,
    showMobMenu: false,
    showSubMobMenu: false,
    typeApp: typeAppDesktop,
    fixedApp: false,
    error: null,
    init: false
}

export const appReducer = createSlice({
    name: 'app',
    initialState,
    reducers: {
        loadingStatus: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        showMobMenu: (state, action: PayloadAction<boolean>) => {
            state.showMobMenu = action.payload
        },
        setTypeApp: (state, action: PayloadAction<string>) => {
            state.typeApp = action.payload
        },
        setFixedApp: (state, action: PayloadAction<boolean>) => {
            state.fixedApp = action.payload
        },
        setErrorRedux: (state, action: PayloadAction<{ type: string, message: string, shortMessage: string } | null>) => {
            state.error = action.payload
        },
        setInit: (state, action: PayloadAction<boolean>) => {
            state.init = action.payload
        },
        setShowSubMobMenu: (state, action: PayloadAction<boolean>) => {
            state.showSubMobMenu = action.payload
        },
    }
})

export const {loadingStatus, showMobMenu, setTypeApp, setFixedApp, setErrorRedux, setInit, setShowSubMobMenu} = appReducer.actions

export const setFixedAppThunk = (option: boolean) => (dispatch: any) => {
    dispatch(setFixedApp(option))
    if (option) {
        document.body.classList.add('_fixed');
    } else {
        document.body.classList.remove('_fixed');
    }
    
}

export const initializeApp = () => async (dispatch: any) => {
    
    dispatch(loadingStatus(true))
    
    try {
        
        const response = await authAPI.authMe()
        let data = {
            className: response.data.class.name,
            firstName: response.data.student_information?.first_name,
            secondName: response.data.student_information?.second_name,
            role: response.data.type,
            school: response.data.school.name
        }
        dispatch(setAuthData(data))
        dispatch(setAuth(true))
        
        dispatch(setUserId(response.data.user_id))
        dispatch(setInit(true))
        
    } catch (err: any) {
        dispatch(setInit(false))
        if (!err.response) {
            console.error('Нет ответа от сервера')
        } else if (err.response.status === 403) {
            console.warn(`Не авторизованный пользователь: ${err.response.status}`)
        } else console.error(err)
        
    }
    dispatch(setInit(true))
    dispatch(loadingStatus(false))
    
}

export default appReducer.reducer