import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { requiredField } from "../../utils/validators/validators";

const Inputs = ({
  type,
  control,
  index,
  register,
  errors,
}: {
  control: any;
  index: number;
  type: string;
  register: any;
  errors: any;
}) => {
  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: `questionArray.${index}.choices`,
    shouldUnregister: true,
  });

  useEffect(() => {
    fields.forEach((item: any, index: number) => {
      update(index, item);
    });
  }, []);

  return (
    <>
      {type === "Input" && (
        <div className="test__inputs">
          <div className={`test__input-wrap ${errors?.questionArray?.[index]?.user_input ? "_error" : ""}`}>
            <span>Ответ: </span>
            <input
              {...register(`questionArray.${index}.user_input`, requiredField)}
              className="test__input"
              type="text"
            />
          </div>
          {errors?.questionArray?.[index]?.user_input && (
            <div className="input__error">{errors?.questionArray?.[index]?.user_input?.message}</div>
          )}
        </div>
      )}
      {type === "MultipleSelect" && (
        <ul className="test__list">
          {fields.map((item: any, i: number) => (
            <li className="test__item" key={item.id}>
              <div className="test__answer-inner">
                <input
                  className="test__answer-input _check"
                  type="checkbox"
                  id={item.id}
                  {...register(`questionArray.${index}.choices.${i}.selected`)}
                />
                <label className="test__answer-opption" htmlFor={item.id}>
                  {item.text ? (
                    item.text
                  ) : (
                    <img src={`${process.env.REACT_APP_BACK_URL}contents/get_content?content_id=${item.content.id}`} />
                  )}
                </label>
              </div>
            </li>
          ))}
          {errors?.questionArray?.[index] && <div className="input__error">Выберите ответ</div>}
        </ul>
      )}
      {type === "Select" && (
        <ul className="test__list">
          {fields.map((item: any, i: number) => (
            <li className="test__item" key={item.id}>
              <div className="test__answer-inner">
                <input
                  className="test__answer-input _radio"
                  type="radio"
                  name={`questionArray.${index}.choices`}
                  id={item.id}
                  value={Number(item.idChoice)}
                  {...register(`questionArray.${index}.choices`)}
                />
                <label className="test__answer-opption" htmlFor={item.id}>
                  {item.text ? (
                    item.text
                  ) : (
                    <img src={`${process.env.REACT_APP_BACK_URL}contents/get_content?content_id=${item.content.id}`} />
                  )}
                </label>
              </div>
            </li>
          ))}
          {errors?.questionArray?.[index] && <div className="input__error">Выберите ответ</div>}
        </ul>
      )}
      {type === "PassInput" && (
        <div className="test__inputs">
          <div className={`test__input-wrap ${errors?.questionArray?.[index]?.user_input ? "_error" : ""}`}>
            <span>Ответ: </span>
            <input
              {...register(`questionArray.${index}.user_input`, requiredField)}
              className="test__input"
              type="text"
            />
          </div>
          {errors?.questionArray?.[index]?.user_input && (
            <div className="input__error">{errors?.questionArray?.[index]?.user_input?.message}</div>
          )}
        </div>
      )}
      {type === "PassInputOption" && (
        <ul className="test__list">
          {fields.map((item: any, i: number) => (
            <li className="test__item" key={item.id}>
              <div className="test__answer-inner">
                <input
                  className="test__answer-input _radio"
                  type="radio"
                  name={`questionArray.${index}.choices`}
                  id={item.id}
                  value={Number(item.idChoice)}
                  {...register(`questionArray.${index}.choices`)}
                />
                <label className="test__answer-opption" htmlFor={item.id}>
                  {item.text}
                </label>
              </div>
            </li>
          ))}
          {errors?.questionArray?.[index] && <div className="input__error">Выберите ответ</div>}
        </ul>
      )}
    </>
  );
};

export default Inputs;
