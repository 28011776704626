import React, {useEffect, useState} from 'react';
import './index.scss';
import {useAppDispatch} from './store/hooks';
import {RouterProvider} from 'react-router-dom';
import router from './routes';
import { setTypeApp} from './store/appReducer';
import {typeAppDesktop, typeAppMobile } from './utils/const';

const routers = router;

function MainApp() {

    const dispatch = useAppDispatch()

    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {

        if (width < 769) {
            dispatch(setTypeApp(typeAppMobile))
        }

    }, [dispatch])

    window.onresize = () => {
        setWidth(window.innerWidth)
        if (width < 769) {
            dispatch(setTypeApp(typeAppMobile))
        } else {
            dispatch(setTypeApp(typeAppDesktop))
        }
    };

    return <RouterProvider router={routers}/>
}

export default MainApp;
