import { Document, Text, Page, View, Image } from "@react-pdf/renderer";
import React from "react";
import { styles } from "./style";
import podpis from "./../../images/podpis/podpis.png";
import pechat from "./../../images/podpis/pechat.png";

const ContractPdf: any = ({ data }: any) => {
  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={[styles.section, styles.MB]}>
          <Text style={styles.textTitle}>ДОГОВОР № {data.numberDoc}</Text>
          <Text style={styles.textTitle}>ВОЗМЕЗДНОГО ОКАЗАНИЯ УСЛУГ</Text>
        </View>
        <View style={[styles.section, styles.JCSB, styles.MBB]}>
          <Text>г. Дубна</Text>
          <Text>{data.dateCurrent}</Text>
        </View>
        <View style={[styles.section, styles.MB]}>
          <Text style={styles.TAJ}>
            <Text style={styles.FW700}>ИП Старцева Н.Г</Text>, ИНН 501006466890, ОГРН 322508100561098, действующей на
            основании государственной регистрации в качестве индивидуального предпринимателя, в дальнейшем именуемое как
            «<Text style={styles.FW700}>Исполнитель</Text>», с одной стороны, и{` ${data.lastName}`} {data.firstName}
            {data.patronymic ? ` ${data.patronymic}` : ""}, (паспорт {`${data.seriesPassport} `}
            {` ${data.numberPassport} `}, выдан {`${data.issued_by} `}
            {data.date_of_issue}), именуемый в дальнейшем «<Text style={styles.FW700}>Заказчик</Text>», а совместно
            именуемые «Стороны», заключили настоящий Договор возмездного оказания услуг (далее – Договор) о следующем:
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>1. Предмет договора</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>1.1.</Text> Исполнитель обязуется оказать Заказчику услуги сопровождения
            прикрепления к {data.school} для прохождения промежуточной аттестации учеником
            {` ${data.studentSecondName}`} {data.studentFirstName}{" "}
            {data.studentPatronymic ? data.studentPatronymic : ""} {data.birthDateStudent} года рождения (далее
            «Ученик»), а Заказчик обязуется оплатить указанные услуги.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>1.2.</Text> Услуги оказываются путем подготовки необходимого для прикрепления и
            прохождения итоговой аттестации пакета документов и передачи данного пакета документов в {data.school}.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>2. Порядок оказания услуг</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.1.</Text> Исполнитель осуществляет подготовку перечня документов необходимых
            для прикрепления к {data.school}.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.2.</Text> Исполнитель перечисляет денежные средства в пользу {data.school} на
            основании заключенного договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.3.</Text> Исполнитель предоставляет техническую базу для проведения онлайн
            тестирования ученика.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.4.</Text> Ученик выполняет тестирование, а исполнитель проверяет его
            результаты.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.5.</Text> Исполнитель подготавливает документы для передачи {data.school} по
            результатам тестирования ученика.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.6.</Text> Исполнитель получает и передает итоговый табель с результатами
            тестирования Заказчику в формате скан-документа.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.7.</Text> Заказчик в праве своими силами и за свой счет получить оригиналы
            документов пересылкой их Почтой России, СДЭК или другой службой доставки на свое усмотрение.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.8.</Text> В процессе оказания услуг Исполнитель оказывает Заказчику
            своевременные консультации относительно процесса оказания услуг, дает Заказчику обратную связь по услугам.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.9.</Text> Услуги оказываются Исполнителем удаленно.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>2.10.</Text> Результатом оказания услуг является получение Учеником документов о
            прохождения промежуточной аттестации в {data.school}.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>3. Права и обязанности Сторон</Text>
        </View>
        <View style={[styles.list]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>3.1.</Text> Исполнитель обязуется:
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.1.1. Заблаговременно ознакомить Заказчика и Ученика с порядком и формой проведения промежуточной
            аттестации, системой оценок при промежуточной аттестации;
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.1.2. Заблаговременно ознакомить Заказчика и Ученика с конкретными требованиями к форме устных ответов и
            оформлению письменных работ, к знаниям и умениям, которые будут предъявляться к Ученику при прохождении
            промежуточной аттестации.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.1.3. Обеспечить надлежащие оказание услуг в соответствии с условиями настоящего Договора;
          </Text>
        </View>
        <View style={[styles.list]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>3.2.</Text> Исполнитель вправе:
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.2.1. Требовать от Заказчика предоставления информации, необходимой для надлежащего оказания услуг.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.2.2. Требовать от Заказчика оплатить услуги в соответствии с условиями настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>3.3.</Text> Заказчик обязан:
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            {" "}
            3.3.1. Предоставить Исполнителю запрашиваемые им документы и информацию.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.3.2. Оплатить услуги Исполнителя в соответствии с разделом 4 настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.3.3. Обеспечить освоение Учеником общеобразовательных программ, необходимых для прохождения промежуточной
            и/или итоговой аттестации.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.3.4. Самостоятельно обеспечить прохождение Учеником промежуточной аттестации.
          </Text>
        </View>
        <View style={[styles.list]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>3.4.</Text> Заказчик вправе:
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.4.1. Запрашивать у Исполнителя информацию о ходе оказания услуг.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.4.2. Вступать в контакт с представителями Исполнителя для целей выяснения порядка прохождения
            промежуточной аттестации.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            3.4.3. Знакомиться с результатами прохождения аттестации Учеником, знакомиться с результатами проверки
            аттестационных работ Ученика.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>4. Порядок расчетов</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.1.</Text> Стоимость услуг проведения аттестационной работы по настоящему
            Договору составляет {data.price} (
            {data.price === "17000"
              ? "семнадцать"
              : "" || data.price === "15000"
              ? "пятнадцать"
              : "" || data.price === "14000"
              ? "четырнадцать"
              : "" || data.price === "13000"
              ? "тринадцать"
              : "" || data.price === "11000"
              ? "одиннадцать"
              : "ошибка"}{" "}
            тысяч) рублей 00 копеек.{" "}
            {data.isExtended ? "Стоимость доступа к расширенным учебным материалам – 3500руб./мес." : ""}
            {data.isCurator ? " Услуга «Персональный куратор» - 3000 руб./мес." : ""}
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.2.</Text> В стоимость услуг входят причитающееся Исполнителю вознаграждение,
            все налоговые платежи и сборы.
          </Text>
        </View>
        <View style={[styles.list]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.3.</Text> Расчеты по настоящему Договору осуществляются в следующем порядке:
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            4.3.1. Оплата вносится Заказчиком единым платежом или в три этапа равными платежами, в течение трех
            календарных месяцев с момента подписания Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.4.</Text> Оплата вносится в безналичном порядке путем перечисления денежных
            средств на расчетный счет Исполнителя, указанный в разделе 11 настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.5.</Text> Оплата может быть осуществлена в личном кабинете пользователя.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>4.6.</Text> Обязательства по оплате считаются исполненными Заказчиком в момент
            поступления денежных средств на расчетный счет исполнителя.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>5. Возврат денежных средств.</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>5.1.</Text> Заказчик вправе расторгнуть настоящий Договор, письменно уведомив об
            этом Исполнителя, в течение{" "}
            <Text style={[styles.FW700]}>
              7 (<Text style={styles.italic}>семи</Text>) календарных дней с момента его подписания.
            </Text>
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>5.2.</Text> Возврат денежных средств осуществляется Исполнителем при условии
            соблюдения п. 5.1 настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>5.3.</Text> Возврат денежных средств Заказчику осуществляется в соответствии со
            стоимостью услуг по п. 4.1 настоящего Договора.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>6. Конфиденциальность</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>6.1.</Text> Под конфиденциальной понимается любая информация (в том числе
            информация, составляющая коммерческую тайну Заказчика) технического, коммерческого, финансового характера,
            персональные данные, сведения о клиентах или контрагентах Заказчика, прямо или косвенно относящаяся к
            взаимоотношениям Сторон, не опубликованная в открытой печати или иным образом не переданная для свободного
            доступа, и ставшая известной Сторонам в ходе выполнения Договора или предварительных переговоров о его
            заключении.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>6.2.</Text> Стороны обязуются не разглашать третьим лицам конфиденциальную
            информацию и не использовать её любым другим образом, кроме как для выполнения задач по Договору.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>6.3.</Text> Стороны обязуются предпринять все необходимые меры для предотвращения
            утечки, разглашения конфиденциальной информации сотрудниками, в том числе и после их увольнения.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>6.4.</Text> Предпринятые каждой Стороной меры по предотвращению разглашения
            конфиденциальной информации должны быть не меньшими, чем меры, предпринимаемые соответствующей Стороной по
            предотвращению разглашения собственной информации, считаемой ею конфиденциальной.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>7. Ответственность сторон</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>7.1.</Text> Стороны несут ответственность в соответствии с действующим
            законодательством РФ и условиями настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>7.2.</Text> Исполнитель не несет ответственности за результат прохождения
            промежуточной и/или итоговой аттестации Учеником.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>7.3.</Text> Заказчик несет полную ответственность за выбор {data.school} в
            качестве того учреждения, в котором Ученик будет проходить промежуточную и/или итоговую аттестацию.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>8. Разрешение споров</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>8.1.</Text> Все вопросы и разногласия, которые могут возникнуть между Сторонами,
            разрешаются путем переговоров.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>8.2.</Text> Стороны обязуются соблюдать досудебный (претензионный) порядок
            урегулирования споров. Срок для ответа на претензию составляет 10 (десяти) рабочих дней с момента ее
            получения Стороной.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>8.3.</Text> В случае невозможности разрешения спора путем переговоров и в
            претензионном порядке, спор передается на рассмотрение в суд по месту нахождения Исполнителя в соответствии
            с законодательством Российской Федерации.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>9. Срок действия договора</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>9.1.</Text> Настоящий Договор действует с момента его подписания Сторонами и до
            момента подписания Акта приема-передачи услуг.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>9.2.</Text> В случае, если Заказчик в течении 5 (пяти) календарных дней с момента
            направления ему Акта приема-передачи услуг уклоняется от подписания Акта приема-передачи услуг по настоящему
            Договору, данный Акт считается подписанным и услуги оказанными в полном объеме, надлежащего качества.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>10. Иные положения</Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.1.</Text> Стороны договорились, что к отношениям Сторон применяется право
            Российской Федерации.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.2.</Text> Стороны обязуются извещать друг друга о любых изменениях в своих
            реквизитах в течение 5 (пяти) рабочих дней с момента такого изменения. В противном случае, сообщения,
            переданные по адресу, указанному в Договоре, считаются переданными надлежащим образом.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.3.</Text> Правоотношения Сторон, возникающие из настоящего Договора или по
            поводу настоящего Договора, регулируются действующим законодательством РФ.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.4.</Text> Все приложения являются неотъемлемой частью настоящего Договора и
            подписываются обеими Сторонами.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.5.</Text> Все уведомления и сообщения в рамках настоящего Договора должны
            направляться Сторонами друг другу в письменной форме путем направления по электронной почте или в
            мессенджерах по электронным адресам/телефонам, указанным в разделе 11 настоящего Договора.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.6.</Text> Все уведомления и сообщения, направленные по электронной почте или в
            мессенджерах по электронным адресам/телефонам, указанным в разделе 9 настоящего Договора, считаются
            надлежащим образом направленными и полученными второй Стороной и имеют юридическую силу.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.7.</Text> Настоящий Договор составлен в двух экземплярах, имеющих одинаковую
            юридическую силу, по одному для каждой из Сторон.
          </Text>
        </View>
        <View style={[styles.list, styles.MBS]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.8.</Text> С даты вступления в силу Договора прекращают действие любые
            предыдущие соглашения, переписка, переговоры между Сторонами, касающихся условий Договора, если они
            противоречат Договору.
          </Text>
        </View>
        <View style={[styles.list, styles.MB]}>
          <Text style={[styles.Item, styles.TAJ]}>
            <Text style={styles.FW700}>10.9.</Text> Стороны договорились, что до момента обмена подписанными
            экземплярами Договора на бумажном носителе, юридической силой обладают подписанные Сторонами скан-копии
            настоящего Договора.
          </Text>
        </View>
        <View>
          <Text style={[styles.textTitle, styles.MB]}>11. Реквизиты Сторон</Text>
        </View>
        <View style={[styles.section, styles.JCSB, styles.MBB]}>
          <View style={[styles.section50, styles.MR]}>
            <Text style={[styles.FW700, styles.MB]}>Исполнитель:</Text>
            <Text style={styles.FW700}>ИП Старцева Наталья Георгиевна:</Text>
            <Text>ИНН 501006466890</Text>
            <Text>ОГРНИП 322508100561098</Text>
            <Text>Адрес: г. Дубна</Text>
            <Text>ул. 9 мая, д. 1, к. 204</Text>
            <Text>Тел: 8-800-500-01-49</Text>
            <Text style={styles.MB}>Эл. Почта: info@sdavaykin.ru</Text>
            <Text>Банковские реквизиты:</Text>
            <Text>Расчетный счет: № 40802810300003927744</Text>
            <Text>в АО «Тинькофф Банк</Text>
            <Text>БИК 044525974</Text>
            <Text style={styles.MB}>к/с 30101810145250000974</Text>
          </View>
          <View style={styles.section50}>
            <Text style={[styles.FW700, styles.MB]}>Заказчик:</Text>
            <Text>
              {data.lastName} {data.firstName} {data.patronymic ? data.patronymic : ""}{" "}
            </Text>
            <Text>Паспортные данные:</Text>
            <Text>
              {data.seriesPassport} {data.numberPassport}, выдан {data.issued_by}
              {` ${data.date_of_issue}`}
            </Text>
            <Text>Телефон: {data.number}</Text>
            <Text>Эл. Почта: {data.email}</Text>
          </View>
        </View>
        <View style={[styles.section, styles.JCSB, styles.MBB, styles.podpisBlockWrap]}>
          <View style={[styles.section50, styles.MR]}>
            <View style={[styles.section50, styles.podpisBlock]}>
              <Image src={pechat} style={styles.pechat} />
              <View style={styles.podpis}>
                <Image style={styles.podpisImg} src={podpis} />
                <Text>/ Старцева Н.Г</Text>
              </View>
             
            </View>
          </View>
          <Text>____________ / __________________</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ContractPdf;
