import { useEffect, useState } from "react";
import { payAPI } from "../../../api/api";
import ContractPdf from "../../PdfPage/ContractPdf";
import { pdf } from "@react-pdf/renderer";
import {
  setRegistrationOne,
  setRegistrationOneData,
  setRegistrationThree,
  setRegistrationFour,
  setRegistrationTwo,
  setRegistrationTwoData,
} from "../../../store/slices/registrationSlice";
import { instance } from "../../../api/axios";
import { setAuth } from "../../../store/authReducer";
import Cryptojs from "crypto-js";
import { openErrorNotification } from "../../../utils/Notification";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getAgreements,
  getClasses,
  getDisciplines,
  getSchools,
} from "../../../store/registrationReducer";
import "./tinkof.css";
import { useNavigate } from "react-router-dom";
import { REGISTRATION_PATH_1 } from "../../../utils/const";

type IItemsList = {
  name: string;
  defaultValue: number | string | boolean | null | undefined;
} & ({ type?: "data" } | { type: "checkbox" });

const Field = (item: IItemsList) => {
  return (
    <>
      {(!item.type || item.type === "data") && (
        <div className="registration__confirm-page-item">
          {item.name}:{" "}
          {item.defaultValue && (
            <span className="text-bold">{item.defaultValue}</span>
          )}
          {!item.defaultValue && "Не указано"}
        </div>
      )}
      {item.type === "checkbox" && (
        <div className="registration__confirm-page-item">
          <div
            className={
              !!item.defaultValue
                ? "registration__fill-checkbox"
                : "registration__empty-checkbox"
            }
          ></div>
          <span className="text-bold">{item.name}</span>
        </div>
      )}
    </>
  );
};

export const RegistrationFour = () => {
  const dispatch = useAppDispatch();
  const [dataForDoc, setDataForDoc] = useState<any>(null);

  const navigate = useNavigate();

  const registration = useAppSelector((state) => state.registration);

  const handlerForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //@ts-ignore
    const values = event.target.elements;
    const { terminalkey, order, amount, description, language } = values;
    //${amount.value}01
    let tokenString = `${amount.value}00${description.value}${language.value}${order.value}i6rbu3q013ivfja2${terminalkey.value}`;
    tokenString = Cryptojs.SHA256(tokenString).toString();

    try {
      const response = await payAPI.init({
        Amount: `${amount.value}00`,
        OrderId: `${order.value}`,
        Description: description.value,
        Token: tokenString,
      });
      console.log(response.data.PaymentURL);
      const PaymentId = response.data.PaymentId;
      const responseBack = await payAPI.initBack({ PaymentId });
      const form = new FormData();
      form.append("type_of_files", "Agreement");
      //@ts-ignore
      form.append("category_id", 1);
      let blobDoc = await pdf(<ContractPdf data={dataForDoc} />).toBlob();
      blobDoc = new File([blobDoc], "Agreement.pdf", {
        type: blobDoc.type,
      });
      form.append("files", blobDoc);
      await instance.post(`upload_documents`, form);
      dispatch(setRegistrationFour(true));
      dispatch(setAuth(true));

      if (response.data.PaymentURL) {
        window.location.replace(response.data.PaymentURL);
        //window.open(response.data.PaymentURL, "_blank");
      } else {
        openErrorNotification("Ошибка оплаты (Т-банк)");
      }
    } catch (e) {
      console.error(e);
      openErrorNotification("Ошибка");
    }
  };

  const dataForDocument = (registerOneData: any, registerTwoData: any) => {
    if (registerOneData && registerTwoData && registration.numberAgreement) {
      let data = {
        firstName: registerTwoData.name_parent,
        lastName: registerTwoData.surname_parent,
        patronymic: registerTwoData.patronymic_parent,
        date: registerTwoData.birth_date_parent,
        seriesPassport: registerTwoData.passport_series_parent,
        numberPassport: registerTwoData.passport_number_parent,
        issued_by: registerTwoData.issued_by_passport_parent,
        date_of_issue: registerTwoData.date_of_issue_passport_parent,
        school:
          registerOneData.school_id === 1
            ? 'ОАНО СОШ "Пенаты"'
            : 'Гимназия "Суханово"',
        studentFirstName: registerTwoData.name_student,
        studentSecondName: registerTwoData.surname_student,
        studentPatronymic: registerTwoData.patronymic_student,
        birthDateStudent: registerTwoData.birth_date_student,
        price: localStorage.getItem("price_without_additions"),
        isExtended:
          registerOneData.enrollment_format === "Standard" ? false : true,
        isCurator: registerOneData.with_teacher,
        number: registerOneData.phone_number,
        email: registerOneData.email,
        numberDoc: registration.numberAgreement,
        dateCurrent: registration.date,
      };
      setDataForDoc(data);
    }
  };

  useEffect(() => {
    if (localStorage.registrationTwoData && !registration.dataTwo) {
      dispatch(
        setRegistrationTwoData(
          //@ts-ignore
          JSON.parse(localStorage.getItem("registrationTwoData"))
        )
      );
    }

    if (localStorage.registrationOneData && !registration.dataOne) {
      dispatch(
        setRegistrationOneData(
          //@ts-ignore
          JSON.parse(localStorage.getItem("registrationOneData"))
        )
      );
    }

    console.log(registration);
    dataForDocument(registration.dataOne, registration.dataTwo);
  }, [
    registration.dataTwo,
    registration.dataOne,
    registration.numberAgreement,
    registration.date,
    registration.cost,
  ]);

  useEffect(() => {
    dispatch(getSchools());
    dispatch(getAgreements());
    if (registration.classNumber !== null) {
      dispatch(getClasses(registration.classNumber));
      dispatch(getDisciplines(registration.classNumber));
    }
  }, [registration.classNumber]);

  const tarifBlock: IItemsList[] = [
    {
      name: "Класс",
      defaultValue: registration.classNumber + " класс",
    },
    {
      name: "Стоимость аттестации",
      defaultValue: registration.cost + " руб/мес",
    },
    {
      name: "Формат обучения",
      defaultValue:
        registration.dataOne?.teaching_format === "Family"
          ? "Семейное образование"
          : "Заочная форма обучения",
    },
    {
      name: "Школа",
      defaultValue: registration.schools.find(
        (s) => s?.id === registration.dataOne?.school_id
      )?.name,
    },
    {
      name: "Доступ к расширенному учебному материалу — 3 500 ₽/мес",
      type: "checkbox",
      defaultValue: registration.dataOne?.enrollment_format === "Extended",
    },
    {
      name: "Помощник (наставник) — 3 000 ₽/мес ",
      type: "checkbox",
      defaultValue: registration.dataOne?.with_teacher,
    },
  ];
  const studentInfoBlock: IItemsList[] = [
    {
      name: "Фамилия",
      defaultValue: registration.dataTwo?.surname_student,
    },
    {
      name: "Имя",
      defaultValue: registration.dataTwo?.name_student,
    },
    {
      name: "Отчество",
      defaultValue: registration.dataTwo?.patronymic_student,
    },
    {
      name: "Пол",
      defaultValue:
        registration.dataTwo?.gender_student === "Male" ? "Мужской" : "Женский",
    },
    {
      name: "Дата рождения",
      defaultValue: registration.dataTwo?.birth_date_student,
    },
    {
      name: "СНИЛС",
      defaultValue: registration.dataTwo?.insurance_number_student,
    },
  ];
  const birthdayCertificateBlock: IItemsList[] = [
    {
      name: "Серия",
      defaultValue: registration.dataTwo?.series_certificate,
    },
    {
      name: "Номер",
      defaultValue: registration.dataTwo?.number_certificate,
    },
    {
      name: "Дата выдачи",
      defaultValue: registration.dataTwo?.date_of_issue_certificate,
    },
    {
      name: "Кем выдан",
      defaultValue: registration.dataTwo?.issued_by_certificate,
    },
  ];
  const parentInfoBlock: IItemsList[] = [
    {
      name: "Фамилия",
      defaultValue: registration.dataTwo?.surname_parent,
    },
    {
      name: "Имя",
      defaultValue: registration.dataTwo?.name_parent,
    },
    {
      name: "Отчество",
      defaultValue: registration.dataTwo?.patronymic_parent,
    },
    {
      name: "Дата рождения",
      defaultValue: registration.dataTwo?.birth_date_parent,
    },
    {
      name: "Способ связи",
      defaultValue: registration.dataTwo?.convenient_messenger,
    },
    {
      name: "Номер телефона",
      defaultValue: registration.dataOne?.phone_number,
    },
    {
      name: "Адрес проживания",
      defaultValue: registration.dataTwo?.residential_address_parent,
    },
  ];
  const parentPassportBlock: IItemsList[] = [
    {
      name: "Серия",
      defaultValue: registration.dataTwo?.passport_series_parent,
    },
    {
      name: "Номер",
      defaultValue: registration.dataTwo?.passport_number_parent,
    },
    {
      name: "Дата выдачи",
      defaultValue: registration.dataTwo?.date_of_issue_passport_parent,
    },
    {
      name: "Кем выдан",
      defaultValue: registration.dataTwo?.issued_by_passport_parent,
    },
    {
      name: "Код подразделения",
      defaultValue: registration.dataTwo?.department_code_parent,
    },
    {
      name: "Адрес регистрации",
      defaultValue: registration.dataTwo?.registration_address_parent,
    },
    {
      name: "Российский паспорт",
      defaultValue:
        registration.dataTwo?.passport_is_rus === "Russian" ? "Да" : "Нет",
    },
  ];

  return (
    <>
      <div className="registration__confirm-page-title _h4">
        Проверьте данные, которые вы заполнили
      </div>

      <div className="registration__confirm-page-text">
        Вы всегда сможете подкорректировать информацию в личном кабинете ученика
        и загрузить недостающие документы и заявления
      </div>

      <div className="registration__confirm-page-tariff">
        <div className="_h4">Вы выбрали тариф:</div>
        {registration.classNumber &&
          tarifBlock.map((item) => <Field {...item} />)}
        <div className="registration__confirm-page-divider"></div>
        <div className="registration__confirm-page-item">
          <span className="_h4">
            ИТОГО:{" "}
            {(registration.cost ?? 0) +
              (registration.dataOne?.with_teacher ? 3000 : 0) +
              (registration.dataOne?.enrollment_format === "Extended"
                ? 3500
                : 0)}{" "}
            ₽/мес
          </span>
        </div>
      </div>

      <div className="registration__confirm-page-info">
        <div className="registration__confirm-page-info-small">
          <div className="registration__confirm-page-info-small-block">
            <div className="_h4">Информация об ученике:</div>
            {registration.classNumber &&
              studentInfoBlock.map((item) => (
                <Field {...item} key={item.name} />
              ))}
          </div>
          <div className="registration__confirm-page-divider-vertical"></div>
          <div className="registration__confirm-page-info-small-block">
            <div className="_h4">Свидетельство о рождении:</div>
            {registration.classNumber &&
              birthdayCertificateBlock.map((item) => (
                <Field {...item} key={item.name} />
              ))}
          </div>
        </div>
        <div className="registration__confirm-page-margin-divider"></div>
        <div className="registration__confirm-page-info-small">
          <div className="registration__confirm-page-info-small-block">
            <div className="_h4">Информация о родителе:</div>
            {registration.classNumber &&
              parentInfoBlock.map((item) => (
                <Field {...item} key={item.name} />
              ))}
          </div>
          <div className="registration__confirm-page-divider-vertical"></div>
          <div className="registration__confirm-page-info-small-block">
            <div className="_h4">Паспорт родителя:</div>
            {registration.classNumber &&
              parentPassportBlock.map((item) => (
                <Field {...item} key={item.name} />
              ))}
          </div>
        </div>
      </div>
      <button
        className="registration__btn-remove btn  registration__btn registration__confirm-page-back-btn"
        onClick={() => {
          dispatch(setRegistrationOne(false));
          dispatch(setRegistrationTwo(false));
          dispatch(setRegistrationThree(false));
          navigate(REGISTRATION_PATH_1);
        }}
      >
        Вернуться к редактированию
      </button>
      <div className="registration__btn-wrap">
        {dataForDoc && (
          <form
            className="payform-tinkoff"
            name="payform-tinkoff"
            onSubmit={handlerForm}
          >
            <input
              className="payform-tinkoff-row"
              type="hidden"
              name="terminalkey"
              value="1715671967566"
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              name="frame"
              value="false"
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              name="language"
              value="ru"
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="Сумма заказа"
              name="amount"
              value={dataForDoc.price}
              required
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="Номер заказа"
              name="order"
              value={dataForDoc.numberDoc}
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="Описание заказа"
              name="description"
              value={"Оплата обучения"}
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="ФИО плательщика"
              name="name"
              value={`${dataForDoc.second_name} ${dataForDoc.first_name} ${
                dataForDoc.patronymic ? dataForDoc.patronymic : ""
              }`}
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="E-mail"
              name="email"
              value={dataForDoc.email}
            />
            <input
              className="payform-tinkoff-row"
              type="hidden"
              placeholder="Контактный телефон"
              name="phone"
              value={dataForDoc.number}
            />

            <input
              className="payform-tinkoff-btn registration__btn-next btn btn_3 registration__btn registration__confirm-page-pay-btn"
              type="submit"
              value="Ура! Оплачиваем!"
            />
          </form>
        )}
        {/* <button className="registration__btn-next btn btn_3 registration__btn" onClick={registrationEnd}>
          Перейти в личный кабинет
        </button> */}
      </div>
    </>
  );
};
