import React from "react";
import { Link } from "react-router-dom";
import { TEXTBOOKS_PATH } from "../../utils/const";

const TextBookItem = ({ name, id, author, url }: { name: string; id: number; author: string; url: string }) => {
  return (
    <li className="textBook-students__item">
      <Link className="textBook-students__link" to={`${TEXTBOOKS_PATH}/${id}`}>
        <div className="textBook-students__img-wrap">
          <img
            className="textBook-students__img"
            src={`${process.env.REACT_APP_BACK_URL}contents/${url.slice(9)}`}
            alt=""
          />
        </div>
        <div className="textBook-students__name">{name}</div>
        <div className="textBook-students__author">{author}</div>
      </Link>
    </li>
  );
};

export default TextBookItem;
