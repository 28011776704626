import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  REGISTRATION_PATH_1,
  REGISTRATION_PATH_2,
  REGISTRATION_PATH_3,
} from "../../utils/const";
import { useAppDispatch } from "../../store/hooks";
import {
  setRegistrationOne,
  setRegistrationThree,
  setRegistrationTwo,
} from "../../store/slices/registrationSlice";

const RegistrationNav: React.FC = () => {
  let location = useLocation();
  const dispatch = useAppDispatch();

  let stepOne = false;
  let stepTwo = false;
  let stepThree = false;

  if (
    location.pathname === REGISTRATION_PATH_1 ||
    location.pathname === REGISTRATION_PATH_2 ||
    location.pathname === REGISTRATION_PATH_3
  ) {
    stepOne = true;
  }

  if (
    location.pathname === REGISTRATION_PATH_2 ||
    location.pathname === REGISTRATION_PATH_3
  ) {
    stepTwo = true;
  }

  if (location.pathname === REGISTRATION_PATH_3) {
    stepThree = true;
  }

  const onStepOne = () => {
    dispatch(setRegistrationOne(false));
    dispatch(setRegistrationTwo(false));
    dispatch(setRegistrationThree(false));
  };

  const onStepTwo = () => {
    dispatch(setRegistrationOne(true));
    dispatch(setRegistrationTwo(false));
    dispatch(setRegistrationThree(false));
  };

  const onStepThree = () => {
    dispatch(setRegistrationOne(true));
    dispatch(setRegistrationTwo(true));
    dispatch(setRegistrationThree(false));
  };

  useEffect(() => {}, [location]);

  return (
    <div className="registration__nav">
      <ul className="registration__nav-list">
        <li className={`registration__nav-item ${stepOne ? "_active" : ""}`}>
          {stepOne ? (
            <button onClick={onStepOne}>Программа обучения</button>
          ) : (
            "Программа обучения"
          )}
        </li>
        <li className={`registration__nav-item ${stepTwo ? "_active" : ""}`}>
          {stepTwo ? (
            <button onClick={onStepTwo}>Личные данные</button>
          ) : (
            "Личные данные"
          )}
        </li>
        <li className={`registration__nav-item ${stepThree ? "_active" : ""}`}>
          {stepThree ? (
            <button onClick={onStepThree}>Подписание договора</button>
          ) : (
            "Подписание договора"
          )}
        </li>
        <li className="registration__nav-item">Оплата обучения</li>
      </ul>
    </div>
  );
};

export default RegistrationNav;
