import React, { useEffect, useState } from "react";
import { openErrorNotification, openSuccessNotification } from "../../utils/Notification";
import { testsApi } from "../../api/api";
import { useFieldArray, useForm } from "react-hook-form";
import Questions from "./Question";

const Test = ({ testId, reload, testTried }: { testId: number; reload: any; testTried: boolean }) => {
  const [initDataForm, setInitDataForm] = useState<null | any>(null);
  const [errorsForm, setErrorsForm] = useState<null | any>(null);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { questionArray: initDataForm },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "questionArray",
    shouldUnregister: true,
  });

  const initData = async (testId: number) => {
    try {
      const { data } = await testsApi.getTest(testId);

      const forSave = data.questions.map((item: any) => {
        if (item.choices) {
          item.choices = item.choices.map((inItem: any) => ({ ...inItem, idChoice: inItem.id }));
        }
        item.idDb = item.id;
        return item;
      });

      setInitDataForm(forSave);
      reset({ questionArray: forSave });
    } catch (e) {
      openErrorNotification("Ошибка загрузки теста");
    }
  };

  const checkError = (arrayData: any) => {
    let isSuccessful = true;

    arrayData.forEach((item: any, index: number) => {
      if (item.question_type === "MultipleSelect") {
        let result = item.choices.find((choiecesItem: any) => choiecesItem.selected === true);
        if (!result) {
          isSuccessful = false;
        }
      }
      if (item.question_type === "PassInputOption" && typeof item.choices === "object") {
        isSuccessful = false;
      }
      if (item.question_type === "Select" && typeof item.choices === "object") {
        isSuccessful = false;
      }
    });
    return isSuccessful;
  };

  const dataForPend = (arrayData: any) => {
    let dataForpend = {
      test_id: testId,
      answers: null,
    };
    let newArray = arrayData.map((item: any) => {
      let itemObject: any = {
        question_id: item.id,
        user_input: item.question_type === "Input" || item.question_type === "PassInput" ? item.user_input : null,
        choices: null,
      };

      if (item.question_type === "MultipleSelect") {
        itemObject.choices = item.choices
          .filter((subItem: any) => subItem.selected)
          .map((subItem: any) => subItem.idChoice);
      }
      if (item.question_type === "Select" || item.question_type === "PassInputOption") {
        itemObject.choices = [Number(item.choices)];
      }

      return itemObject;
    });
    dataForpend.answers = newArray;
    return dataForpend;
  };

  const onSubmit = async (data: any) => {
    if (checkError(data.questionArray)) {
      try {
        const response = await testsApi.completeTest(dataForPend(data.questionArray));
        setErrorsForm(response.data.details);
        openSuccessNotification("Успешная отправка теста");
        reload();
      } catch (e) {
        openErrorNotification("Ошибка отправки теста");
      }
    } else {
      openErrorNotification("Необходимо заполнить все поля в тесте");
    }
  };

  const resetColor = () => {
    setErrorsForm(null);
  };

  useEffect(() => {
    initData(testId);
  }, []);

  return (
    <section className="test">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="test__title">Тест</div>
        <ul className="test__list">
          {fields?.map((item: any, index) => (
            <li
              key={item.id}
              className={`test__item ${
                errorsForm &&
                item.idDb in errorsForm &&
                errorsForm[item.idDb].user_score < errorsForm[item.idDb].max_score &&
                "_error"
              }`}
              data-id={item.idDb}
            >
              <Questions
                type={item.question_type}
                item={item}
                watch={watch}
                index={index}
                control={control}
                errors={errors}
                register={register}
              />
            </li>
          ))}
        </ul>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto", gridGap: "10px", width: "fit-content" }}>
          <button type="submit" className="test__btn btn btn_3">
            Проверить
          </button>
          {testTried && (
            <button type="button" onClick={resetColor} className="test__btn btn btn_4">
              Пройти еще раз
            </button>
          )}
        </div>
      </form>
    </section>
  );
};

export default Test;
