export const HostMainSite = "https://sdavaykin.ru/";
export const MAIN_PATH = "/";
export const LESSONS_PATH = "/profile/preparation/lessons";
export const LESSON_PATH = "/profile/preparation/lesson";
export const ERROR_PATH = "/error";
export const NOT_COUNT_PATH = "/NotCount";
export const PAYMENT_SUCCESSFUL_PATH = "/payment_successful";
export const PAYMENT_FAILED_PATH = "/payment_failed";
export const ENTRY_PATH = "/entry";
export const REGISTRATION_PATH = "/registration";
export const PROFIlE_PATH = "/profile";
export const REGISTRATION_PATH_GENERAL = "/registration-steps";
export const REGISTRATION_PATH_1 = "/registration-steps/registration-one";
export const REGISTRATION_PATH_2 = "/registration-steps/registration-two";
export const REGISTRATION_PATH_3 = "/registration-steps/registration-three";
export const REGISTRATION_PATH_4 = "/registration-steps/registration-four";
export const DOCUMENTS_PATH = "/profile/documents";
export const TEXTBOOKS_PATH = "/profile/textbooks";
export const CERTIFICATION_PATH = "/profile/certification";
export const PREPARATION_PATH = "/profile/preparation";
export const typeAppMobile = "mobile";
export const typeAppDesktop = "desktop";
