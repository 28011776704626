import React from 'react';
import IconStore from "../../media/iconStore";


const Error: React.FC = () => {
    return (
        <section className="page404">
            <div className="container">
                <div className="_h2 promo-page__title">
                    Такой страницы не существует
                </div>
                <div className="promo-page__bg-wrap ">
                    <img className="promo-page__img" src={IconStore.Image404} alt="img_blog.svg" width="1140"
                         height="400"/>
                </div>
            </div>
        </section>
    );
};


export default Error;