import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { lessonsApi } from "../../api/api";
import Loading from "../Loading/Loading";
import Test from "./Test";
import { openErrorNotification } from "../../utils/Notification";
import TestSuccess from "./TestSusscess";
import parse from "html-react-parser";
import { HTMLReactParserOptions } from "html-react-parser";
import katex from "katex";

const Lesson = () => {
  const [nameLesson, setNameLesson] = useState<null | string>(null);
  const [nameDisciplines, setNameDisciplines] = useState<null | string>(null);
  const [content, setContent] = useState<null | any>(null);
  const [testId, setTestId] = useState<null | number>(null);
  const [testCompleted, setTestCompleted] = useState<boolean>(false);
  const [testTried, setTestTried] = useState<boolean>(false);
  const [reloadFlag, setRealodFlag] = useState<boolean>(false);
  const [numberLessons, setNumberLessons] = useState<null | number>(null);
  const [idDisciplines, setIdDisciplines] = useState<null | number>(null);
  const params = useParams<{ id: string }>();

  const initData = async (id: number) => {
    try {
      const { data } = await lessonsApi.getLesson(id);
      setNameLesson(data.name);
      setContent(data.content);
      setTestId(data.test?.id || null);
      setNameDisciplines(data.discipline.name);
      const idDisciplines = data.discipline.id;
      setIdDisciplines(idDisciplines);
      setTestTried(data?.test?.was_tried)
      let count = 1;
      const response = await lessonsApi.getLessons(idDisciplines);
      response.data.forEach((item: any) => {
        item.lessons.forEach((i: any) => {
          if (i.id === Number(params.id)) {
            setNumberLessons(count);
            return;
          } else {
            count = count + 1;
          }
        });
      });
      if (data.test?.is_completed) {
        setTestCompleted(true);
      }
    } catch (e) {
      console.error(e);
      openErrorNotification("Ошибка загрузки урока");
    }
  };

  const reload = () => {
    initData(Number(params.id));
    setRealodFlag(!reloadFlag)
  };

  useEffect(() => {
    initData(Number(params.id));
  }, []);

  if (!nameLesson) {
    return <Loading />;
  }

  const options: HTMLReactParserOptions = {
    replace(domNode: any) {
      if (domNode?.attribs?.class === "math-tex" && domNode.name === "span") {
        let getData = domNode.children[0].data.slice(1, -1);
        let isDisplayMode;
        if (getData[0] === "(") {
          isDisplayMode = false;
        } else {
          isDisplayMode = true;
        }
        getData = getData.slice(1, -1);
        const resultData = katex.renderToString(getData, {
          throwOnError: false,
          displayMode: isDisplayMode,
        });

        return parse(resultData);
      }
    },
  };

  return (
    <>
      <section className="lesson">
        <div className="lesson__title _h3">{nameDisciplines || "Ошибка"}</div>
        <div className="lesson__theme">
          § {numberLessons || "Ошибка"}. {nameLesson ? nameLesson : "Ошибка"}
        </div>
        <div className="lesson__content editor">
          {content.length ? <div style={{width: '100%'}}>{parse(content, options)}</div> : "Текст урока еще не добавлен"}
        </div>
      </section>
      {testId ? <Test testId={testId} reload={reload} testTried={testTried}/> : "Тест еще не добавлен" }
      {testCompleted && <TestSuccess idDisciplines={idDisciplines} testId={testId} flag={reloadFlag}/>}
    </>
  );
};

export default Lesson;
