import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { loadingStatus } from "../../store/appReducer";
import { TextBookService } from "../../services/TextBookService";

const TextBook = () => {
  const [book, setBook] = useState<any | null>(null);

  const dispatch = useAppDispatch();

  const params = useParams<{ id: string }>();

  const fetch = async (id: number) => {
    dispatch(loadingStatus(true));
    try {
      const response = await TextBookService.getBookInfo(id);
      console.log(response);
      setBook(response.data);
    } catch (e) {
      console.error(e);
    }
    dispatch(loadingStatus(false));
  };

  useEffect(() => {
    fetch(Number(params.id));
  }, [params]);

  return (
    <div style={{ height: "100vh" }}>
      {book && (
        <>
          <div className="_h2" style={{ marginBottom: "50px" }}>
            {book.name}
          </div>
          <div className="textBook__inner">
            <div>Автор: {book.author}</div>
            <div>Класс: {book.class.name}</div>
            <div>Дисциплина: {book.discipline.name}</div>
          </div>
        </>
      )}
      <Link
        to={`${process.env.REACT_APP_BACK_URL}books/get_book?book_id=${params.id}`}
        target="_blank"
        className="btn btn_4"
      >
        Открыть книгу
      </Link>
    </div>
  );
};

export default TextBook;
