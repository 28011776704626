import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getMyDisciplines } from "../../store/profileReducer";
import Loading from "../Loading/Loading";
import { Link } from "react-router-dom";
import { LESSONS_PATH } from "../../utils/const";

const SubjectPreparationList = () => {
  const dispatch = useAppDispatch();

  const subjects = useAppSelector((state) => state.profile.myDisciplines);

  console.log(subjects)

  useEffect(() => {
    dispatch(getMyDisciplines());
  }, [dispatch]);

  return (
    <>
      {subjects ? (
        <ul className="preparation__subject-list">
          {subjects.map((item: any, index) => {
            return (
              <li key={index} style={{height: 'auto'}}>
                <Link to={`${LESSONS_PATH}/${item.discipline.id}`} className="registration__subject-item" style={{height: '100%'}}>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.discipline.icon_url }}
                    className="registration__subject-img-wrap"
                  />

                  <div
                    className="registration__subject-name text-bold"
                    dangerouslySetInnerHTML={{ __html: item.discipline.name }}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default SubjectPreparationList;
